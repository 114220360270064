// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.versions[data-v-202cb310] {
  padding-top: 1em;
}
.versions p[data-v-202cb310] {
  padding-top: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Devices/DeviceSoftware.vue"],"names":[],"mappings":";AA6DA;EACA,gBAAA;AACA;AAEA;EACA,gBAAA;AACA","sourcesContent":["<template>\n  <b-container class=\"versions\" style=\"padding: 0\">\n    <h2>Current software versions</h2>\n    <div v-if=\"!software.result\">{{ software.message }}</div>\n    <div\n      v-else-if=\"\n        software.result.EventDetail && software.result.EventDetail.details\n      \"\n    >\n      <div\n        v-for=\"(version, component) in software.result.EventDetail.details\"\n        :key=\"component\"\n      >\n        <b>{{ component }}</b\n        >: {{ version }}\n      </div>\n      <div v-if=\"software.result.dateTime\">\n        <i\n          >Recorded {{ dayOfSnapshot.toLowerCase() }} at {{ timeOfSnapshot }}</i\n        >\n      </div>\n      <p>\n        Current released software versions are listed\n        <a href=\"https://github.com/TheCacophonyProject/saltops#branch-prod\"\n          >here</a\n        >.\n      </p>\n    </div>\n  </b-container>\n</template>\n\n<script>\nimport { mapState } from \"vuex\";\nimport { toStringTodayYesterdayOrDate } from \"@/helpers/datetime\";\n\nexport default {\n  name: \"DeviceDetail\",\n  props: {\n    software: {\n      type: Object,\n      required: true,\n    },\n  },\n  computed: mapState({\n    dayOfSnapshot: function () {\n      if (this.software.result.dateTime) {\n        return toStringTodayYesterdayOrDate(\n          new Date(this.software.result.dateTime)\n        );\n      }\n    },\n    timeOfSnapshot: function () {\n      if (this.software.result.dateTime) {\n        const thisDate = new Date(this.software.result.dateTime);\n        return thisDate.toLocaleTimeString();\n      }\n    },\n  }),\n};\n</script>\n<style scoped>\n.versions {\n  padding-top: 1em;\n}\n\n.versions p {\n  padding-top: 1em;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
